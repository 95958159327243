import React, { useContext, useEffect, useState } from "react"
import { AppButton, AppInput, Label } from ".."
import {
  Box,
  Grid,
  Modal,
  Popover,
  Typography,
  TextField,
  Autocomplete as TextAutocomplete,
} from "@mui/material"
import { COLORS } from "../../constants"
import { ReactComponent as Cross } from "../../assets/svg/Cross.svg"
import { ReactComponent as ExclamationCircle } from "../../assets/svg/ExclamationCircle.svg"
import {
  addFootballStats,
  createOrganizationTeamStat,
  getOrganizationTeam,
  updateFootballStats,
} from "../../api/auth"
import { useSnackbar } from "notistack"
import { getError } from "../../utils/getError"
import AppContext from "../../Context"
import moment from "moment"
import QuantityInput from "../AppInput/NumberInput"

function FootBallStats({
  organization,
  visible,
  handleClose,
  teamID,
  playerId,
  playerStatOption,
  statData
}) {
  const { enqueueSnackbar } = useSnackbar()
  const [dropDownText, setDropDownText] = useState("")
  const [dateOfGameError, setdateOfGameError] = useState(false)
  const [teamPlayedError, setTeamPlayedError] = useState(false)
  const { organizationData, user } = useContext(AppContext)
  const token = localStorage.getItem("token")
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: organization ? 350 : "80%",
    borderRadius: 6,
    maxHeight: "90%",
    overflow: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4
  }
  const [anchorEl, setAnchorEl] = useState(null)
  const [state, setState] = useState({
    date_of_game: "",
    team_played: "",
    team_played_custom: "",
    game_started: true,
    touchdowns: 0,
    yards: 0,
    tackles: 0,
    passing_completions: 0,
    passing_yards: 0,
    passing_touchdowns: 0,
    qb_interceptions: 0,
    rushing_attempts: 0,
    rushing_yards: 0,
    rushing_touchdowns: 0,
    receptions: 0,
    targets: 0,
    receiving_yards: 0,
    yards_per_carry: 0,
    tackles: 0,
    sacks: 0,
    interceptions: 0,
    forced_fumbles: 0,
    fumble_recoveries: 0,
    field_goals_made: 0,
    field_goals_attempted: 0,
    loading: false,
    teams: []
  })
  const {
    date_of_game,
    team_played,
    team_played_custom,
    result,
    game_started,
    touchdowns,
    field_goals_attempted,
    yards,
    passing_completions,
    field_goals_made,
    passing_yards,
    passing_touchdowns,
    qb_interceptions,
    rushing_attempts,
    rushing_yards,
    rushing_touchdowns,
    receptions,
    targets,
    receiving_yards,
    yards_per_carry,
    tackles,
    loading,
    teams,
    sacks,
    interceptions,
    forced_fumbles,
    fumble_recoveries
  } = state

  const points_scored_calculated =
    Number(rushing_touchdowns || 0) * 2 +
    Number(receptions || 0) * 3 +
    Number(yards_per_carry || 0) * 1

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (statData && Object.keys(statData).length !== 0) {
      handleChange("date_of_game", statData?.date_of_game)
      if (teams?.length && teams?.some((e) => e?.id == statData?.team_played)) {
        setDropDownText(teams?.find((e) => e?.id == statData?.team_played)?.team_name)
      }
      handleChange("team_played", statData?.team_played)
      handleChange("team_played_custom", statData?.team_played_custom)
      handleChange("points_scored_calculated", statData?.points_scored_calculated)
      handleChange("touchdowns", statData?.touchdowns)
      handleChange("field_goals_attempted", statData?.field_goals_attempted)
      handleChange("game_started", statData?.game_started)
      handleChange("yards", statData?.yards)
      handleChange("passing_completions", statData?.passing_completions)
      handleChange("passing_touchdowns", statData?.passing_touchdowns)
      handleChange("qb_interceptions", statData?.qb_interceptions)
      handleChange("rushing_attempts", statData?.rushing_attempts)
      handleChange("rushing_yards", statData?.rushing_yards)
      handleChange("rushing_touchdowns", statData?.rushing_touchdowns)
      handleChange("targets", statData?.targets)
      handleChange("receptions", statData?.receptions)
      handleChange("receiving_yards", statData?.receiving_yards)
      handleChange("yards_per_carry", statData?.yards_per_carry)
      handleChange("tackles", statData?.tackles)
      handleChange("passing_yards", statData?.passing_yards)
      handleChange("field_goals_made", statData?.field_goals_made)
      handleChange("interceptions", statData?.interceptions)
      handleChange("fumble_recoveries", statData?.fumble_recoveries)
      handleChange("forced_fumbles", statData?.forced_fumbles)
      handleChange("sacks", statData?.sacks)
    } else {
      handleChange("date_of_game", "")
      handleChange("team_played", "")
      handleChange("result", "")
      handleChange("team_played_custom", "")
      handleChange("touchdowns", 0)
      handleChange("game_started", true)
      handleChange("field_goals_attempted", 0)
      handleChange("yards", 0)
      handleChange("passing_completions", 0)
      handleChange("passing_yards", 0)
      handleChange("field_goals_made", 0)
      handleChange("passing_touchdowns", 0)
      handleChange("qb_interceptions", 0)
      handleChange("rushing_attempts", 0)
      handleChange("rushing_yards", 0)
      handleChange("points_scored", 0)
      handleChange("targets", 0)
      handleChange("rushing_touchdowns", 0)
      handleChange("receptions", 0)
      handleChange("receiving_yards", 0)
      handleChange("yards_per_carry", 0)
      handleChange("interceptions", 0)
      handleChange("fumble_recoveries", 0)
      handleChange("sacks", 0)
      handleChange("forced_fumbles", 0)
      setDropDownText("")
      handleChange("tackles", 0)
    }
  }, [statData, visible])

  const getData = async () => {
    try {
      handleChange("loading", true)
      const res = await getOrganizationTeam(``, token)
      handleChange("teams", res?.data?.results)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClosePopup = () => {
    setAnchorEl(null)
  }

  const handleSubmit = async () => {
    try {
      if (date_of_game === "") {
        alert("Please enter date of game")
      }
      if (team_played === "" && team_played_custom === "") {
        alert("Please enter team played")
      } else {
        handleChange("loading", true)
        const payload = {
          date_of_game,
          team_played,
          team_played_custom,
          points_scored: points_scored_calculated,
          game_started,
          field_goals_attempted,
          touchdowns,
          yards,
          passing_completions,
          passing_yards,
          field_goals_made,
          passing_touchdowns,
          qb_interceptions,
          rushing_attempts,
          rushing_yards,
          rushing_touchdowns,
          targets,
          receptions,
          receiving_yards,
          yards_per_carry,
          tackles,
          fumble_recoveries,
          interceptions,
          forced_fumbles,
          sacks
        }
        if (user?.role === "Parent") {
          payload.player_id = playerId?.id // Add player_id if user's role is parent
        }
        await addFootballStats(payload, token)
        handleChange("loading", false)
        handleChange("date_of_game", "")
        handleChange("team_played", "")
        handleChange("result", "")
        handleChange("team_played_custom", "")
        handleChange("game_started", true)
        handleChange("field_goals_attempted", 0)
        handleChange("touchdowns", 0)
        handleChange("yards", 0)
        handleChange("passing_completions", 0)
        handleChange("passing_yards", 0)
        handleChange("field_goals_made", 0)
        handleChange("passing_touchdowns", 0)
        handleChange("qb_interceptions", 0)
        handleChange("rushing_attempts", 0)
        handleChange("rushing_yards", 0)
        handleChange("points_scored", 0)
        handleChange("targets", 0)
        handleChange("rushing_touchdowns", 0)
        handleChange("receptions", 0)
        handleChange("receiving_yards", 0)
        handleChange("yards_per_carry", 0)
        handleChange("interceptions", 0)
        handleChange("fumble_recoveries", 0)
        handleChange("forced_fumbles", 0)
        handleChange("sacks", 0)
        setDropDownText("")
        handleChange("tackles", 0)
        handleClose(true)
        enqueueSnackbar(`Stats has been added`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        })
      }
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const handleEdit = async () => {
    try {
      if (date_of_game === "") {
        alert("Please enter date of game")
      }
      if (team_played === "" && team_played_custom === "") {
        alert("Please enter team played")
      } else {
        handleChange("loading", true)

        if (organization) {
          const payload = {
            date_of_game,
            team_played,
            result,
            organization: organizationData?.id,
            team: teamID
          }
          await createOrganizationTeamStat(payload, token)
        } else {
          const payload = {
            date_of_game,
            team_played,
            team_played_custom,
            game_started,
            field_goals_attempted,
            touchdowns,
            yards,
            passing_completions,
            passing_yards,
            field_goals_made,
            passing_touchdowns,
            qb_interceptions,
            rushing_attempts,
            rushing_yards,
            rushing_touchdowns,
            targets,
            receptions,
            receiving_yards,
            yards_per_carry,
            tackles,
            interceptions,
            fumble_recoveries,
            forced_fumbles,
            sacks
          }
          if (user?.role === "Parent") {
            payload.player_id = playerId?.id // Add player_id if user's role is parent
          }
          await updateFootballStats(statData?.id, payload, token)
        }
        handleChange("loading", false)
        handleChange("date_of_game", "")
        handleChange("team_played", "")
        handleChange("result", "")
        handleChange("team_played_custom", "")
        handleChange("game_started", true)
        handleChange("field_goals_attempted", 0)
        handleChange("touchdowns", 0)
        handleChange("yards", 0)
        handleChange("passing_completions", 0)
        handleChange("passing_yards", 0)
        handleChange("field_goals_made", 0)
        handleChange("passing_touchdowns", 0)
        handleChange("qb_interceptions", 0)
        handleChange("rushing_attempts", 0)
        handleChange("rushing_yards", 0)
        handleChange("points_scored", 0)
        handleChange("targets", 0)
        handleChange("rushing_touchdowns", 0)
        handleChange("receptions", 0)
        handleChange("receiving_yards", 0)
        handleChange("yards_per_carry", 0)
        handleChange("interceptions", 0)
        handleChange("fumble_recoveries", 0)
        handleChange("forced_fumbles", 0)
        handleChange("sacks", 0)
        setDropDownText("")
        handleChange("tackles", 0)
        handleClose(true)
        enqueueSnackbar(`Stats has been added`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        })
      }
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  return (
    <Modal
      open={visible}
      onClose={() => {
        setTeamPlayedError(true)
        setdateOfGameError(true)
        handleClose(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: 1000 }}
    >
      <Box sx={style} className="postBox">
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          className="mb-3"
        >
          <div className="font-24 font-bold greyColor">Add Stats</div>
          <AppButton
            width={35}
            height={35}
            borderRadius={35}
            className={"min-width-remove"}
            backgroundColor={COLORS.white}
            borderColor={"#D9D9D9"}
            title={<Cross />}
            onClick={() => handleClose(false)}
          />
        </Grid>

        <Grid container spacing={3}>
          {user?.role == "Parent" && (
            <Grid item container xs={12} md={12} spacing={3}>
              <Grid item xs={12} md={4}>
                <AppInput
                  label={"Adding stats for"}
                  height={40}
                  // select
                  onChange={handleChange}
                  value={playerId?.name}
                  name={"playerId"}
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <AppInput
              label={"Date of game"}
              max={moment().format("YYYY-MM-DD")}
              type={"date"}
              height={40}
              required={true}
              onChange={handleChange}
              value={date_of_game}
              name={"date_of_game"}
            />
            {dateOfGameError === true ? (
              <Label text={"required"} fontNormal color={COLORS.orange} />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Label text={"Team Played"} fontNormal />
            <TextAutocomplete
              freeSolo
              id="free-solo-2-demo"
              disableClearable
              value={
                team_played_custom === "" ? dropDownText : team_played_custom
              }
              onChange={(event, newValue) => {
                handleChange("team_played_custom", "")
                handleChange(
                  "team_played",
                  teams[event?.target?.dataset?.optionIndex]?.id
                )
                setDropDownText(newValue)
                console.log("On change")
              }}
              inputValue={
                team_played_custom === "" ? dropDownText : team_played_custom
              }
              onInputChange={(event, newInputValue) => {
                console.log("On Input change")
                handleChange("team_played_custom", newInputValue)
                handleChange("team_played", "")
              }}
              options={teams?.map(option => option.team_name)}
              // getOptionLabel={(option) => option.team_name}
              label={false}
              renderInput={params => (
                <TextField
                  {...params}
                  size="small"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#D9D9D9",
                      borderWidth: 1
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#D9D9D9"
                    }
                    // Add your desired z-index value here
                  }}
                  label=""
                  InputLabelProps={{ shrink: false }}
                  InputProps={{
                    ...params.InputProps,
                    type: "search"
                  }}
                />
              )}
            />
            {teamPlayedError === true ? (
              <Label text={"required"} fontNormal color={COLORS.orange} />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              select
              selectOptions={[
                <option value={true}>Yes</option>,
                <option value={false}>No</option>
              ]}
              label={"Game Started"}
              onChange={handleChange}
              value={game_started}
              name={"game_started"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Touchdowns"}
              onChange={handleChange}
              value={touchdowns}
              name={"touchdowns"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Field Goals Attempted"}
              onChange={handleChange}
              value={field_goals_attempted}
              name={"field_goals_attempted"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Yards"}
              onChange={handleChange}
              value={yards}
              name={"yards"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Passing Completions"}
              onChange={handleChange}
              value={passing_completions}
              name={"passing_completions"}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Passing Touchdowns"}
              onChange={handleChange}
              value={passing_touchdowns}
              name={"passing_touchdowns"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"QB Interceptions"}
              onChange={handleChange}
              value={qb_interceptions}
              name={"qb_interceptions"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Rushing Yards"}
              onChange={handleChange}
              value={rushing_yards}
              name={"rushing_yards"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Rushing Attempts"}
              onChange={handleChange}
              value={rushing_attempts}
              name={"rushing_attempts"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Rushing Touchdowns"}
              onChange={handleChange}
              value={rushing_touchdowns}
              name={"rushing_touchdowns"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Targets"}
              onChange={handleChange}
              value={targets}
              name={"targets"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Receptions"}
              onChange={handleChange}
              value={receptions}
              name={"receptions"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Receiving Yards"}
              onChange={handleChange}
              value={receiving_yards}
              name={"receiving_yards"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Yards Per Carry"}
              onChange={handleChange}
              value={yards_per_carry}
              name={"yards_per_carry"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Tackles"}
              onChange={handleChange}
              value={tackles}
              name={"tackles"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Passing Yards"}
              onChange={handleChange}
              value={passing_yards}
              name={"passing_yards"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Field Goals Made"}
              onChange={handleChange}
              value={field_goals_made}
              name={"field_goals_made"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Fumble Recoveries"}
              onChange={handleChange}
              value={fumble_recoveries}
              name={"fumble_recoveries"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Interceptions"}
              onChange={handleChange}
              value={interceptions}
              name={"interceptions"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Sacks"}
              onChange={handleChange}
              value={sacks}
              name={"sacks"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Forced Fumbles"}
              onChange={handleChange}
              value={forced_fumbles}
              name={"forced_fumbles"}
            />
          </Grid>
        </Grid>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopup}
          classes={{ paper: "comfirm-paper" }}
          sx={{ width: 350 }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
        >
          <Grid className="p-4">
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <ExclamationCircle className="mt-2" />
              </Grid>
              <Grid item xs={10}>
                <Typography id="modal-modal-description">
                  Are you sure you want to add this stats to your profile. This
                  cannot be changed later.
                </Typography>
              </Grid>
            </Grid>
            <Grid className="mt-3">
              <AppButton
                title={"Yes"}
                height={40}
                onClick={() => {
                  handleClosePopup()
                  handleClose(false)
                }}
                width={"100%"}
                backgroundColor={COLORS.primary}
                className={"mb-2"}
                color={COLORS.white}
              />
              <AppButton
                title={"Cancel"}
                height={40}
                onClick={handleClosePopup}
                width={"100%"}
                backgroundColor={"#fff"}
                className={"mb-2"}
                borderColor={"#D9D9D9"}
              />
            </Grid>
          </Grid>
        </Popover>
        <Grid container justifyContent={"flex-end"}>
          <AppButton
            title={"Save"}
            backgroundColor={COLORS.primary}
            color={COLORS.white}
            width={300}
            height={40}
            className={"mt-5"}
            loading={loading}
            // disabled={disabled}
            onClick={statData && Object.keys(statData).length !== 0 ? handleEdit : handleSubmit}
          />
        </Grid>
      </Box>
    </Modal>
  )
}

export default FootBallStats
